import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "66.66666666666666%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAQL/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAYHyaSs6Mv/EABwQAAEDBQAAAAAAAAAAAAAAAAEAAjEDERITIf/aAAgBAQABBQKkNhwsHcfCeSpX/8QAFREBAQAAAAAAAAAAAAAAAAAAEEH/2gAIAQMBAT8Bp//EABYRAAMAAAAAAAAAAAAAAAAAAAEQEf/aAAgBAgEBPwGBf//EAB0QAAIABwEAAAAAAAAAAAAAAAABEBESITFBcYH/2gAIAQEABj8CdT0Tbyx9LHsP/8QAGRABAQEBAQEAAAAAAAAAAAAAAREAITFR/9oACAEBAAE/ITFCJOfMgvAAmsLFEXChqaqDA40pVq9d/9oADAMBAAIAAwAAABDPz//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCU1//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPxDKq//EABwQAQEAAgIDAAAAAAAAAAAAAAERAEEhMVHR4f/aAAgBAQABPxBhLfcrFjghkOaKI1NT5ho2CO494z0LKeE5MYVtwagT3i0ZCjtc/9k=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Polaroid photo of the band in front of trees",
        "title": "Polaroid photo of the band in front of trees",
        "src": "/static/577088c5fe49163f0b5615cba5202508/2e753/fall2024.jpg",
        "srcSet": ["/static/577088c5fe49163f0b5615cba5202508/69549/fall2024.jpg 288w", "/static/577088c5fe49163f0b5615cba5202508/473e3/fall2024.jpg 576w", "/static/577088c5fe49163f0b5615cba5202508/2e753/fall2024.jpg 1152w", "/static/577088c5fe49163f0b5615cba5202508/74f4b/fall2024.jpg 1728w", "/static/577088c5fe49163f0b5615cba5202508/de5bb/fall2024.jpg 2304w", "/static/577088c5fe49163f0b5615cba5202508/9b69a/fall2024.jpg 3072w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      